body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  position: fixed;
  width: 100%;
  top: 0px;  
  z-index: 999999;
  padding: 0px !important;
}
header a.nav-link{
  color: #000;
  font-weight: bold;
  padding-right: 25px !important;
}
.Toastify__toast-container--top-right {
  z-index: 9999999;
}
img.logo {
  border-radius: 100%;
  width: 60px;
  margin-right: 40px;
}
header.home > nav {
  /* background:rgb(0 0 0 / 59%) !important; */
}
header.home > nav a {
  color: #fff;
}
.bg-nav {
  background: rgb(0 0 0 / 59%) !important;
  border-radius: 16px;
  width: 100%;
}

img.logo {
  margin-left: 20px;
}
.btn-view-pass:hover {
  color: #000;
}

.btn-view-pass {
  border: 0px;
  top: 4px !important;
}
a.btn-forgotpassword {
  display: block;
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  top: -12px;
  color: #fff;
}