header.viewPage  a.nav-link{
    color: #fff !important;
}
header  a.nav-link:hover{
    color: #d75353 !important;
}
.btn-primary{
    background: #d75353 !important;
    border-color: #d75353 !important;
}
.wapper-account {
    min-height: 100vh;
    padding-top: 125px;
    color: #fff;
    background: linear-gradient(118deg, rgba(195, 34, 110, 1) 0%, rgba(253, 187, 45, 1) 100%);
}
footer.viewPage{
    display: none;
}
.navAccount {
    background: rgb(47 54 62 / 77%);
    padding: 30px 15px 15px;
    border-radius: 5px;
}

.navAccount a {
    color: #fff;
    font-weight: bold;
    display: block;
    padding: 15px 20px !important;
    margin-bottom: 15px;
    border-radius: 5px;
}

.navAccount a.active,.navAccount a:hover {
    background: #009688;;
    color: #fff;
}

.navAccount ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}
footer.pageview.py-3.my-4 {
    display: none;
}
.navAccount a span {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    border-radius: 100%;
    background: transparent;
    margin-right: 15px;
    display: inline-block;
    text-align: center;
    padding-top: 3px;
}

.navAccount a.active span,.navAccount a:hover span {
    background: #fff;
    color: #009688;
}
.tabc {
    background: rgb(47 54 62 / 77%);
    padding: 25px;
    border-radius: 20px;
    margin-bottom: 30px;
}
.btn-copy {
    background: #009688;
    color: #fff;
}
.box-avatar .avatar {
    width: 120px;
    border-radius: 100%;
   
}

.box-avatar {
    text-align: center;
     border-bottom: 1px solid #fff;
    margin-bottom:15px;
    padding-bottom:20px;
    
}

.box-avatar h4 {
    margin-top: 20px;
    font-size: 22px;
}

.box-avatar span {
    color: #eda231;
    font-weight: bold;
    font-size: 17px;
}

.group-text label {
    color: #a0aec0;
    font-size: 16px;
}

.group-text p {
    font-size: 22px;
    font-weight: bold;
}

.group-text .input-group {
    margin-top: 15px;
}
.tablec th,.tablec td{
    text-align: center;
    background: transparent;
    color: #fff;
}
.register-container,
.login-container {
    min-height: 100vh;
    background: url('../../../public/images/ft.png');
    background-size: 100%;
}
.register-container form,
.login-container form {
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-top: 140px;
    background: rgb(47 54 62 / 80%);
    color: #fff;
    text-align: center;
    padding: 30px;
    border-radius: 20px;
}
.register-container form label,
.login-container form label {
    color: #000;
}

.register-container form input,
.login-container form input {
    margin-bottom: 20px;
}

.btn-register1,
.btn-login1 {
    max-width: calc(50% - 5px);
    margin-top: 0px;
    margin-right: 10px;   
    height: 46px;
    background: #db464e !important;
    border:1px solid #db464e !important;
}
.btn-register1:hover,
.btn-login1:hover {
    background: #db464e !important;
}
.btn-register2,
.btn-login2 {
    color: #fff;
    max-width: calc(50% - 5px);
    border: 1px solid #fff;
    height: 46px;
}
.btn-login2:hover{
    background-color: #fff;
    color: #2e363d;
}
.list-btn-swap {
    margin-bottom: 30px;
}
.btn-buy-game{
    text-transform: uppercase;
}
.list-btn-swap button {
    height: 40px;
    padding: 5px 30px;
    border: 0px;
    margin-right: 10px;
    border-radius: 3px;
    background: #607D8B;
    color: #fff;
}

.list-btn-swap button.active {
    background: #009688;
    border: 1px solid;
}
.box-avatar-img {
    width: 120px;
    margin: auto;
    background: #fff;
    border-radius: 100%;
}
.text-tb {
    color: #ffc107;
    font-weight: bold;
}

button.btn-view-nav-mb {
    display: none;
}
.item-view-aff {
    text-align: center;
}
@media(max-width:767px){
    .navAccount ul {
        display: none;
    }
    button.btn-view-nav-mb {
        display: inline-block;
        display: inline-block;
        min-width: 150px;
        border-radius: 5px;
        background: #009688;
        color: #fff;
        border: 1px solid #fff;
        height: 36px;
    }
    .navAccount ul.active{
        display: block;
    }
    .list-btn-swap {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: auto auto auto;
    }
}