/* CardWolf.css */
.wapper-game-card-wolf {
    background: url('../../../../public/images/bg-game.jpg');
    background-size: cover;
    min-height: 600px;
    padding: 0px;
    border-radius: 25px;
    overflow: hidden;
    display: grid;
}
.memory-game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
  }
  
  .score-board {
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin-bottom: 20px;
    font-size: 18px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    grid-gap: 10px;
  }
  
  .card {
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: relative;
    perspective: 1000px;
    background: transparent !important; 
    border: 2px solid #FFC107;
    border-radius: 9px; 
  }
  
  .card .inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d; 
    transform: rotateY(0deg); 
  }
  
  .card.flipped .inner {
    transform: rotateY(180deg);
  }
  
  .card .front,
  .card .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 8px;
  }
  
  .card .front {
    background: url('../../../../public/images/qss.webp');
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: white;
    background-size: contain;
  }
  
  .card .back {
    transform: rotateY(180deg);
  }
  
  .card .back img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .time-bar {
    width: 400px;
    height: 20px;
    background-color: #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .time-bar-inner {
    height: 100%;
    background: linear-gradient(101deg, #673AB7 0%, rgba(212, 78, 159, 1) 33%, rgba(232, 167, 69, 1) 63%, rgba(253, 187, 45, 1) 100%);
    transition: width 0.2s ease;
    border: 1px solid #fff;
    border-radius: 21px;
  }
  
  .play-button,
  .level-complete button {
    padding: 15px 50px;
    font-size: 20px;   
    background: url('../../../../public/images/bg-btn.png');
    border: none;
    border-radius: 15px;
    font-weight: bold;
    font-family: 'ArimaKoshi-ExtraBold';
    color: #fff;   
    background-size: 100% 100%;
}
  
.button-end-game{
    padding: 15px 25px;
    font-size: 20px;   
    background: url('../../../../public/images/btn-end.png');
    border: none;
    border-radius: 15px;
    font-weight: bold;
    font-family: 'ArimaKoshi-ExtraBold';
    color: transparent;
    background-size: 100% 100%;
    margin-right: 20px;
}
.btn-mute-game-card{
    background: url('../../../../public/images/mute.png');
    background-size: 100% 100%;
    border-radius: 100%;
    color: transparent;
    border: 0px !important;
    width: 40px;
    height: 40px;
    position: relative;
    top: -6px;
}
.btn-mute-game-card.active{
    background: url('../../../../public/images/music.png');    
    background-size: 100% 100%;
    border-radius: 100%;
    color: transparent;
    border: 0px !important;
    width: 40px;
    height: 40px;
    position: relative;
    top: -6px;
}
  .game-over {
    text-align: center;
  }
  .game-over,
  .level-complete {
    text-align: center;
}

.level-complete img {
    width: 220px;
}

.game-over h2 {
    color: #FF9800;
    font-size: 70px;
    font-weight: bold;
    text-shadow: -1px -1px 0px #ffffff, 1px -1px 0 #fff, -1px 1px 0 #ffffff, 1px 1px 0px #fff;
}

.game-over h5 {
    font-size: 40px;
    margin-bottom: 20px;
    color: #FF9800;
    text-shadow: -1px -1px 0px #ffffff, 1px -1px 0 #fff, -1px 1px 0 #ffffff, 1px 1px 0px #fff;
}

.game-over h4 {
    font-size: 30px;
}

@media(max-width:767px){
    .card {
        width: 70px;
        height: 70px;
    }
    
    .grid {
        grid-template-columns: repeat(4, 70px);
    }
    
    .memory-game {
        width: 100%;
        padding: 20px;
    }
    
    .wapper-game-card-wolf {
        display: block;
        padding-top: 40px;
    }
    
    .time-bar {
        width: 100%;
    }
    
    .score-board {
        width: 100%;
    }
}