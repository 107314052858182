@font-face {
    font-family: 'NunitoSans-Black';
    src: url('../../../public/fonts/NunitoSans-Black.eot');
    src: url('../../../public/fonts/NunitoSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/NunitoSans-Black.woff2') format('woff2'),
        url('../../../public/fonts/NunitoSans-Black.woff') format('woff'),
        url('../../../public/fonts/NunitoSans-Black.ttf') format('truetype'),
        url('../../../public/fonts/NunitoSans-Black.svg#NunitoSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../../../public/fonts/NunitoSans-Bold.eot');
    src: url('../../../public/fonts/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/NunitoSans-Bold.woff2') format('woff2'),
        url('../../../public/fonts/NunitoSans-Bold.woff') format('woff'),
        url('../../../public/fonts/NunitoSans-Bold.ttf') format('truetype'),
        url('../../../public/fonts/NunitoSans-Bold.svg#NunitoSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans-ExtraBold';
    src: url('../../../public/fonts/NunitoSans-ExtraBold.eot');
    src: url('../../../public/fonts/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/NunitoSans-ExtraBold.woff2') format('woff2'),
        url('../../../public/fonts/NunitoSans-ExtraBold.woff') format('woff'),
        url('../../../public/fonts/NunitoSans-ExtraBold.ttf') format('truetype'),
        url('../../../public/fonts/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans-SemiBoldItalic';
    src: url('../../../public/fonts/NunitoSans-SemiBoldItalic.eot');
    src: url('../../../public/fonts/NunitoSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/NunitoSans-SemiBoldItalic.woff2') format('woff2'),
        url('../../../public/fonts/NunitoSans-SemiBoldItalic.woff') format('woff'),
        url('../../../public/fonts/NunitoSans-SemiBoldItalic.ttf') format('truetype'),
        url('../../../public/fonts/NunitoSans-SemiBoldItalic.svg#NunitoSans-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'NunitoSans';
    src: url('../../../public/fonts/NunitoSans-Regular.eot');
    src: url('../../../public/fonts/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/NunitoSans-Regular.woff2') format('woff2'),
        url('../../../public/fonts/NunitoSans-Regular.woff') format('woff'),
        url('../../../public/fonts/NunitoSans-Regular.ttf') format('truetype'),
        url('../../../public/fonts/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'ArimaKoshi-ExtraBold';
    src: url('../../../public/fonts/ArimaKoshi-ExtraBold.eot');
    src: url('../../../public/fonts/ArimaKoshi-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../../public/fonts/ArimaKoshi-ExtraBold.woff2') format('woff2'),
        url('../../../public/fonts/ArimaKoshi-ExtraBold.woff') format('woff'),
        url('../../../public/fonts/ArimaKoshi-ExtraBold.ttf') format('truetype'),
        url('../../../public/fonts/ArimaKoshi-ExtraBold.svg#ArimaKoshi-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

h1,
h2,
h3,
h4 {
    font-family: 'ArimaKoshi-ExtraBold' !important;
}

p,
span {
    font-family: 'NunitoSans';
}

.page-home img {
    width: 100%;
}

#sc_banner>.container {
    height: 100vh;
}

.view-sm h4 {
    font-size: 21px;
    color: #6d381d;
}

.view-sm button {
    color: #6d381d;
    background: transparent;
    border: 2px solid #6d381d;
    margin-bottom: 20px;
    border-radius: 3px;
}

#sc_banner {
    min-height: 100vh;    
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}

.img-about {
    border-radius: 100%;
}

#sc_presale {
    min-height: 100vh;  
    background-size: cover;
    background-repeat: no-repeat;
}
.box-presale {
    padding: 10px;
    background: #FFC344;
    border-radius: 20px;
    box-shadow: 2px 7px 5px 0px #623407;
}
.box-presale-bg {
    padding: 10px 70px 60px;
    border-radius: 20px;
    border: 3px dashed #623407;
}
.box-countdowntime {
    display: inline-block;
}

.box-countdowntime span.item-time {
    padding: 10px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    min-width: 85px;
    background: #d45350;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 5px;
}

.box-countdowntime span.item-time span.time-text {
    display: block;
    font-size: 14px;
    color: #fff;
    line-height: 15px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.box-countdowntime span.item-time span.time-t {
    font-size: 28px;
    line-height: 20px;
    color: #fff;
    font-weight: bold;
}

span.item-time-line {
    font-size: 34px;
    font-weight: bold;
    position: relative;
    top: -11px;
}

.tokens-sold-line {
    display: block;
    background: #fff;
    border-radius: 20px;
    position: relative;
    height: 24px;
    overflow: hidden;
}

.tokens-sold-sell {
    background: #39a657;
    display: inline-block;
    height: 24px;
    position: absolute;
    left: 0px;
    border-radius: 20px;
}

.tokens-sold-line span {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.tokens-sold>p {
    text-align: left;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    font-size: 18px;
    color: #684638;
}

.tokens-sold-stage {
    color: #684638;
    font-size: 16px;
    margin-top: 7px;
    font-weight: bold;
    text-align: left;
}

.tokens-sold-stage span {
    color: #FF5722;
}

.ip-payment {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
}

.gr-ip-payment {
    position: relative;
}

.gr-ip-payment label {
    display: block;
    text-align: left;
    margin-bottom: 5px;
    color: #684638;
    font-weight: bold;
}

.gr-ip-payment button {
    position: absolute;
    top: 0px;
    right: 0px;
}

.gr-ip-payment input {
    width: 100%;
    height: 42px;
    border-radius: 5px;
    border: 1px solid #684638;
    padding: 5px 10px;
    margin-bottom: 16px;
    font-weight: bold;
    color: #000;
}

button.btn-payment-max {
    border: 0px;
    border-radius: 3px;
    background: transparent;
    font-size: 14px;
}

.coin-rate {
    width: 100%;
    text-align: center !important;
    margin-top: 10px;
}

.coin-rate p {
    position: relative;
    display: flex;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: rgb(215 83 83);
}

.text-next {
    margin-top: 23px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #504949;
}

.coin-rate div hr {
    display: inline-block;
    border-width: 2px;
    flex: 1 1;
    border-color: #000000;
    opacity: 1;
}

.coin-rate div span {
    z-index: 10;
    background: transparent;
    display: inline-block;
    padding: 4px 20px;
    position: relative;
    opacity: 1 !important;
    border-radius: 3px;
    color: #000;
    font-weight: bold;
}

.box-menber2 {
    display: none;
}

.type-pay button {
    width: calc(50%);
    font-weight: bold;
    height: 40px;  
    border: 0px solid #674539;
    color: #674539;
    background: #fff;
}
.btn-payment-bnb{
    border-radius: 20px 0px 0px 20px;
}
button.btn-payment-usdt{
    border-radius: 0px 20px 20px 0px;
}
.type-pay button.active {
    background: #d75353;
    color: #fff;
    border-color: #d75353;
}

.total-wallet {
    text-align: left;
    margin-top: 15px;
    font-weight: bold;
    color: #684638;
}

.gr-btn-payment button {
    min-width: 200px;
    background: #db464e;
    border: 1px solid #db464e;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    padding: 10px 35px;
    border-radius: 5px;
}

.gr-btn-payment {
    padding-bottom: 25px;
    padding-top: 20px;
}

.height100 {
    height: 100vh;
}

div#sc_presale {
    padding: 100px 0px;
    background: linear-gradient(171deg, rgba(56, 135, 169, 1) 0%, rgba(58, 193, 166, 1) 52%, rgba(100, 227, 152, 1) 100%);
}

div#sc_member {
    padding: 15px;
    /* background: url('../../../public/images/3156029.jpg'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
}

div#bg_member {
    padding: 20px;
    color: #fff;
    display: grid;
    grid-template-columns: auto;
}

div#bg_member span {
    font-size: 38px;
    text-shadow: -1px -1px 0px #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    color: #f04162;
    font-weight: bold;
    font-family: 'NunitoSans-Bold';
}

div#bg_member span {
    font-size: 36px;
}

div#sc_about {
    min-height: 100vh;
    padding-top: 100px;
    position: relative;
    overflow: hidden;
     background: url('../../../public/images/bg-about.png');
     background-size: 100%;
}

div#sc_about h2 {
    font-size: 64px;
    margin-bottom: 48px;
    color: #fff;
    font-weight: bold;
    text-shadow: -1px -1px 0px #000, 1px -1px 0 #fff, -1px 1px 0 #000, 1px 1px 0px #fff;
}
.boxt-text-about {
    border-radius: 20px;
    padding: 50px 30px;
    border: 7px double #dbdbdb;
    margin-top: 210px;
    background: linear-gradient(133deg, rgba(255, 60, 172, 1) 0%, rgba(120, 75, 160, 1) 52%, rgba(43, 134, 197, 1) 100%);
}

.boxt-text-about p {
    font-size: 20px !important;
    color: #fff;
}
.title-about {
    position: relative;
}

div#sc_about p {
    font-weight: bold;
}

div#sc_about p {
    font-size: 22px;
}



.icon-about img.iabout2 {
    width: 100%;
    max-width: 320px;
}

.icon-about img.iabout1 {
    width: 100%;
    max-width: 150px;
    position: absolute;
    bottom: 67px;
    right: 30px;
}

.icon-about .box-icon-about {
    position: relative;
}

.wapper-bg {
    background: linear-gradient(92deg, #FF5F6D, #FFC371);
}


.item-timeline {
    background: #ff6e82;
    border: 2px solid #795548;
    border-radius: 20px;
    padding: 10px;
    color: #000;
    font-weight: bold;
    height: 100%;
}

.timeline-content {
    border: 2px solid #795548;
    border-radius: 20px;
    height: 100%;
    position: relative;
    padding: 40px 10px 0px;
}

.timeline-content .title {
    position: absolute;
    top: -25px;
    width: 100%;
    left: 0px;
    text-align: center;
}

.timeline-content .title span {
    background: #3F51B5;
    color: #fff;
    font-size: 24px;
    padding: 10px 30px;
    border-radius: 20px;
    border: 5px double #2d397b;
    text-transform: uppercase;
    font-weight: bold;
}
.item-timeline1 h3.title span {
    background: #d98c44;
    border-color: #965e2a;
}
.item-timeline2 h3.title span {
    background: #8e6ac5;
    border-color: #74579f;
}
.item-timeline3 h3.title span {
    background: #ce4755;
    border-color: #a83c47;
}
.item-timeline li {
    margin-bottom: 15px;
}

.item-timeline ul {
    margin: 0px;
    padding-left: 15px;
}

.item-timeline1 {
    background: rgb(248,198,109);
background: linear-gradient(180deg, rgba(248,198,109,1) 0%, rgba(246,169,83,1) 100%);
}

.item-timeline2 {
    background: rgb(193,141,222);
background: linear-gradient(180deg, rgba(193,141,222,1) 0%, rgba(153,116,215,1) 100%);
}

.item-timeline3 {
    background: rgb(242, 115, 106);
    background: linear-gradient(180deg, rgba(242, 115, 106, 1) 0%, rgba(196, 64, 80, 1) 100%);
}

#sc_roadmap {
    background: url('../../../public/images/bg-map.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

div#bg_roadmap {
    background: rgb(0 0 0 / 15%);
    padding-top: 100px;
    padding-bottom: 200px;
    color: #fff;
    overflow: hidden;
}


.main-timeline {
    position: relative;
}

.main-timeline:before {
    content: "";
    height: calc(100% + 100px);
    border-left: 3px dashed #fff;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: -50px;
}

div#sc_tokenomics {
    padding-top: 100px;
    padding-bottom: 100px;
}

.item-tokenomics-img {
    height: 300px;
    text-align: center;
}

.item-tokenomics-img img {
    height: 100%;
    width: auto;
}

.item-tokenomics-main {
    text-align: center;
    margin-top: 10px;
}

.item-tokenomics-main h3 {
    color: rgb(112, 53, 25);
    font-size: 22px;
    font-weight: bold;
}

.item-tokenomics-main h4 {
    color: #cd400c;
    font-size: 26px;
    font-weight: bold;
}


div#sc_tokenomics h2 {
    font-size: 64px;
    margin-bottom: 60px;
}


.slick-slide .item {
    padding: 0px 20px;
}

.wapper-bg-main {
    background: linear-gradient(45deg, rgba(65, 88, 208, 1) 0%, rgba(200, 80, 192, 1) 50%, rgba(255, 186, 62, 1) 100%);
}

div#sc_roadmap .title-h {
    font-size: 74px;
    margin-bottom: 120px;
    margin-top: 100px;
    position: relative;
    text-shadow: -1px -1px 9px #000d19, 1px -1px 0 #000d19, -1px 1px 0 #000d19, 1px 1px 0 #000d19;

}

div#sc_roadmap .title-h span {
    position: relative;
}


.height100 {
    height: 100%;
}

.bn-img img {
    animation: circleScale 3s infinite;
}

@keyframes circleScale {
    0% {
        transform: scale(0.8);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.8);
    }
}

.contact-img img {
    transform: scaleX(-1);
    max-width: 375px;
}

div#sc_banner h1 {
    color: #2a3f5c;
    font-size: 65px;
    font-weight: bold;
    margin-bottom: 25px;
}

div#sc_banner h5 {
    color: #3dab5e;
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 0px;
}

div#sc_banner p {
    font-size: 22px;
}

p.presale-text1 {
    font-weight: bold;
    font-size: 18px;
    color: #684638;
}

.box-presale h2 {
    font-weight: bold;
    text-align: center;
    color: rgb(112, 53, 25);   
    font-size: 70px; 
}

#sc_rankings {
    background: url('../../../public/images/rangking.webp');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}

#bg_rankings {
    background: rgb(0 0 0 / 60%);
    padding-top: 100px;
    padding-bottom: 100px;
    color: #fff;
    overflow: hidden;
}

.ip-payment {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
}

.box-rankings {
    border: 7px double #dbdbdb;
    border-radius: 20px;
    background: linear-gradient(357deg, rgb(239 120 111) 0%, rgb(166 49 63) 100%);
    padding: 45px 30px 20px;
}

.ranking-item {
    background: #f5b650;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: 55px auto auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px 1px #000;
}

.ranking-item-stt span {
    display: block;
    font-size: 30px;
    border-radius: 100%;
    font-weight: bold;
    text-shadow: -1px -1px 4px #872220, 1px -1px 4px #872220, -1px 1px 4px #872220, 1px 1px 4px #872220;
}

.ranking-item-name {
    font-size: 30px;
    text-align: left;
    color: #8c121d;
    font-weight: bold;
}

.ranking-item-name svg {
    color: #fff;
    margin-right: 9px;
}

.ranking-item-name span {
    font-family: 'NunitoSans-ExtraBold';
}

.box-rankings h3 {   
    color: #8b2832;
    font-size: 55px;
    margin-bottom: 30px;
    font-weight: bold;
}

.box-rankings h3 span {
    position: relative;
    color: #fff;
}

.ranking-item-stt {
    max-width: 65px;
}

.box-rankings h3 span:before {
    content: "";
    position: absolute;
    width: 50px;
    height: 50px;
    background: url('../../../public/images/title-ranking.png');
    background-repeat: no-repeat;
    background-size: 100%;
    z-index: 99;
    left: -27px;
    top: -21px;
}

.ranking-item-point>span {
    background: #cd9f79;
    padding: 3px 7px;
    border-radius: 5px;
    text-shadow: -1px -1px 0px #872220, 1px -1px 0px #872220, -1px 1px 0px #872220, 1px 1px 0px #872220;
}

.ranking-item-point>span svg {
    color: #ffeb3b;
}

.ranking-item-point {
    text-align: right;
    padding-top: 10px;
    padding-right: 13px;
    font-size: 18px;
    font-weight: bold;
}

.box-leaderboard {
    text-align: left;
    max-width: 500px;
    font-size: 17px;
}

.box-leaderboard h4 {
    font-size: 55px;
    margin-bottom: 37px;
    color: #fff;
    font-weight: bold;
    text-shadow: -1px -1px 0px #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    margin-top: 140px;
}

.box-leaderboard ul li {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    color: #FF9800;
}

#sc_tokenomics {
    background: linear-gradient(43deg, #185a9d 0px, #43cea2 100%);
    background-size: 100%;
    min-height: 100vh;
    background-position: center;
}

div#sc_tokenomics h2 {
    color: #fff;
    font-weight: bold;
    font-size: 64px;
    font-family: 'NunitoSans-Bold' !important;
    text-transform: uppercase;
    margin-top: 50px;
}

.tokenomics-info h2 span {
    color: #2d7d9d;
    text-shadow: -1px -1px 1px #fff, 1px -1px 1px #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
    font-weight: bold;
}

.tokenomics-info h4 {
    color: #fff;
}

.tokenomics-info {
    color: #fff;
}

.tokenomics-info ul li {
    list-style: none;
    margin-bottom: 15px;
    position: relative;
    font-weight: bold;
}

.tokenomics-info ul {
    margin-top: 30px;
}

.tokenomics-info ul li:before {
    border: 1px solid #fff;
}

li.tokenomics1:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #f74a38;
    position: absolute;   
    left: -32px;
    top: 2px;
}

li.tokenomics2:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #7dd866;
    position: absolute;   
    left: -32px;
    top: 2px;
}

li.tokenomics3:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #ff9058;
    position: absolute; 
    left: -32px;
    top: 2px;
}

li.tokenomics4:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #004ca8;
    position: absolute;   
    left: -32px;
    top: 2px;
}

li.tokenomics5:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #4574f8;
    position: absolute;   
    left: -32px;
    top: 2px;
}

li.tokenomics6:before {
    content: "";
    width: 20px;
    height: 20px;
    background: #823f83;
    position: absolute;   
    left: -32px;
    top: 2px;
}

.contact-img img {
    transform: scaleX(-1);
    max-width: 375px;
}
#sc_contact{
    background: url('../../../public/images/ft.png');
}
div#bg_contact {
    background: linear-gradient(181deg, rgb(0 0 0 / 30%), rgb(0 0 0 / 79%));
    min-height: 100vh;
    color: #fff;
    padding: 140px;
}

#sc_contact h2 {
    font-size: 64px;
    margin-bottom: 50px;
    text-shadow: -1px -1px 9px #000d19, 1px -1px 0 #000d19, -1px 1px 0 #000d19, 1px 1px 0 #000d19;
}

#sc_contact p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 60px;
}

.fr-contact input {
    width: 100%;
    height: 45px;
    border-radius: 18px;
    padding: 7px 14px;
    font-size: 15px;
    margin-bottom: 20px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

.fr-contact textarea {
    width: 100%;
    border-radius: 18px;
    padding: 7px 14px;
    font-size: 15px;
    margin-bottom: 20px;
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
}

button.btn-contact {
    background: rgb(87, 101, 242);
    color: #fff;
    min-width: 120px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid rgb(87, 101, 242);
    font-size: 17px;
}


footer.home.py-3.my-4 {
    background: linear-gradient(181deg, #667eea,#764ba2);
    margin: 0px !important;
    color: #fff;
}

footer p {
    border-top: 1px solid #fff;
    padding-top: 30px;
}

footer p,
footer a {
    color: #fff !important;
}

footer a {
    font-size: 20px !important;
}

.logo-footer .logo {
    width: 120px;
    margin: 0px;
}

.logo-footer {
    text-align: center;
    padding: 20px 0px;
}

button.btn.btn-register-now {
    background: #F44336;
    border: 1px solid #F44336;
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    padding: 7px 15px;
    border-radius: 5px;
    margin-top: 20px;
    animation: shake2 2s infinite;
}

@keyframes shake2 {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px) rotate(-5deg);
    }

    50% {
        transform: translateX(5px) rotate(5deg);
    }

    75% {
        transform: translateX(-5px) rotate(-5deg);
    }

    100% {
        transform: translateX(0);
    }
}

.txt-tb {
    color: #f3450e;
    font-size: 22px;
}

.fr-contact input::placeholder,
.fr-contact textarea::placeholder {
    color: #fff;
}


#sc_team_op {
    position: relative;
    z-index: 10;
    padding-top: 120px;
    padding-bottom: 120px;
    background: #3a2b4d;
}
#sc_team_op h2.title-team{
    color: #fff;
}
.item-team.item-team2 {
    background: linear-gradient(180deg, rgba(120,66,130,1) 0%, rgba(179,82,149,1) 37%, rgba(232,104,154,1) 78%, rgba(200,73,143,1) 100%);
}

.item-team2 .item-team-bg {
    border: 2px solid #c37fb8;
}

.item-team .item-team-bg {
    border: 2px solid #7cb4bc;
    border-radius: 20px;
    padding: 35px 10px 44px;
    height: 100%;
}

.item-team {
    height: 100%;
    background: linear-gradient(180deg, rgba(73,121,135,1) 0%, rgba(72,146,151,1) 37%, rgba(82,183,162,1) 78%, rgba(74,172,159,1) 100%);
    border-radius: 20px;
    padding: 7px;
}
.view-sm button {
    overflow-wrap: break-word;
    width: 100%;
}

.item-saleround-main h3 {
    color: rgb(100 41 14);
    font-weight: bold;
}

button.btn-coming-soon {
    background: #b54649;
    color: #fff;
    border-radius: 5px;
    padding: 9px 30px;
    font-size: 18px;
    border: 0px;
}

.contact-msg p {
    margin-bottom: 10px !important;
}

.text-dex {
    color: rgb(255 255 255);
    font-family: 'NunitoSans-Bold';
    font-weight: bold;
    font-size: 26px;
    margin-bottom: 40px;
    margin-top: 25px;
}
.box-text-presale img {
    border-radius: 100%;
}
.box-text-presale {
    text-align: center;
    max-width: 500px;
    margin: auto;
}
.coin-rate>div {
    display: flex;
    margin-bottom: 20px;
}

.list-partner {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 50px;
}

.item-partner {
    background-size: 100% 100%;
    position: relative;
    padding: 30px 20px;
}

.list-partner {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 25px;
}

.item-partner-img {
    height: 130px;
}

.item-partner-img img {
    height: 100%;
    width: auto;
}

.item-partner h4 {
    font-size: 20px;
    margin-top: 12px;
    font-weight: bold;
    margin-bottom: 20px;
}


div#sc_partners {
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 90px;
}

div#sc_partners h2 {
    font-size: 64px;
    margin-bottom: 40px;
    margin-top: 100px;
    position: relative;
    color: #f6b949;
}

.item-partner {
    background: rgb(255 255 255 / 75%);
    border-radius: 5px;
}

img.avatar-team {
    border-radius: 100%;
    height: 130px;
    margin-bottom: 12px;
    object-fit: cover;
    width: 130px;
}


h2.title-team {
    font-size: 64px;
    font-weight: bold;
    margin-bottom: 75px;
    text-transform: capitalize;
}

.item-team {
    margin: auto;
    max-width: 370px;
}

.item-team>span {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 19px;
    display: block;
    color: #795548;
}
.item-team h4{
    color: #fff;
    text-shadow: -1px -1px 0px #000d19, 1px -1px 0 #000d19, -1px 1px 0 #000d19, 1px 1px 0 #000d19;
}
.item-team div {
    font-weight: bold;
    font-size: 16px;
    color: #434343;
}

.box-text-presale span {
    font-size: 16px;
    line-height: 20px;
}

.box-text-presale p {
    font-size: 16px;
    line-height: 24px;
}

.box-text-presale strong {
    font-size: 36px;
}

.box-text-presale .text-dex {
    margin-top: 0px;
}

.box-text-presale img {max-height: 370px;width: auto;}
@media(max-width:767px) {
    .box-text-presale {
        margin-bottom: 40px;
    }
    .bg-nav {
        display: block !important;
        background: transparent !important;
    }
    .text-end {
        text-align: right !important;
        position: absolute;
        top: 9px;
        right: 0px;
    }
    #sc_team:before {
        content: unset;
    }

    .main-timeline .title {
        font-size: 30px;
    }

    .text-dex {
        font-size: 18px;
    }

    .tabc {
        overflow-y: auto;
    }

    header .navbar a.nav-link {
        color: #fff !important;
    }

    header .navbar-toggler {
        background: #fff !important;
    }

    .navbar-toggler {
        color: #fff;
    }

    header.viewPage,
    header.viewPage nav.navbar,
    header.home nav.navbar {
        background: #2f363e !important;
    }

    header.home>nav {
        background: transparent !important;
    }

    div#sc_banner {
        padding-top: 55px;
        min-height: auto !important;
    }

    header.home nav.navbar {
        position: relative;
    }

    a.navbar-brand .logo {
        margin: 0 !important;
        position: absolute;
        top: 0px;
        z-index: 999999;
        left: calc(50% - 30px);
    }

    a.navbar-brand {
        text-align: center;
        margin: 0px;
    }

    header a.nav-link {
        padding-right: 0px !important;
    }

    .box-partners-content {
        padding: 40px 20px 100px;
    }

    .box-partners-content h2 {
        font-size: 28px;
    }

    .list-partner {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }


    .box-countdowntime span.item-time {
        font-size: 14px;
        padding: 10px 10px;
    }

    div#sc_about h2 {
        font-size: 42px;
    }

    .title-about:before {
        top: 2px;
    }

    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

    .box-presale {
        padding: 10px;
    }
    .box-presale-bg {
        padding: 10px;
    }
   
    .title-about:before {
        top: 2px;
    }

    img.iabout3 {
        bottom: auto;
        top: 20px;
        right: -105px;
    }

    div#sc_about {
        padding-bottom: 130px;
        background-size: cover;
        background-position: center;
    }

    .icon-about {
        bottom: -55px;
        right: -75px;
    }

    img.iabout4 {
        display: none;
    }

    .logo-footer .logo {
        width: 120px;
        margin: 0px;
    }

    .logo-footer {
        text-align: center;
        padding: 20px 0px;
    }

    .title-about:before {
        top: 2px;
    }

    img.iabout3 {
        bottom: auto;
        top: 20px;
        right: -105px;
    }

    .icon-about {
        bottom: -55px;
        right: -75px;
    }

    img.iabout4 {
        display: none;
    }

    div#sc_roadmap .title-h {
        font-size: 40px;
        margin-top: 0px;
        margin-bottom: 30px;
    }

    .item-team {
        margin-bottom: 50px;
    }

    .timeline-content {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .main-timeline .title {
        font-size: 30px;
    }

    .item-team {
        margin-bottom: 50px;
        height: auto;
    }
    .tokenomics-info {
        margin-top: 40px;
    }
    .tokenomics-info p {
        font-size: 16px !important;
        font-weight: bold;
    }
    div#sc_partners h2 {
        color: #fff;
        font-size: 40px;
        margin-top: 0px;
        margin-bottom: 60px;
    }

    .timeline-icon {
        left: 0px;
    }

    .main-timeline .timeline:nth-child(1) .timeline-content:after {
        right: 30px;
    }

    .main-timeline .timeline:nth-child(3) .timeline-content:after {
        left: auto;
        top: calc(100% - 25px);
        right: 0px;
        transform: rotate(48deg);
    }

    .main-timeline .timeline:nth-child(6) .timeline-content:after {
        right: calc(100% + -170px);
        top: calc(100% - 18px);
    }

    .item-tokenomics {
        margin-bottom: 30px;
    }

    div#sc_banner h1 {
        font-size: 44px;
    }

    header.home.p-3.text-bg-white {
        position: relative;
    }

    .could5 {
        width: 220px;
        height: 40px;
    }

    .could3 {
        width: 200px;
        height: 40px;
    }

    #sc_tokenomics,
    #sc_roadmap,
    #sc_presale {
        background-size: cover;
    }

    .text-end ul li:nth-child(2) {
        display: none;
    }

    div#bg_contact {
        padding: 100px 20px !important;
    }

    button.btn-contact {
        margin-bottom: 50px;
    }

    #sc_contact h2 {
        font-size: 50px;
    }

    .main-timeline:before {
        content: unset;
    }

    .timeline-content:after {
        content: unset;
    }

    div#sc_tokenomics h2 {
        font-size: 50px;
    }

    .tokenomics-info h2 span {
        color: #48a8a1;
    }

    button.btn-coming-soon {
        margin-bottom: 50px;
    }

    .ranking-item-stt span {
        font-size: 20px;
    }

    .ranking-item-stt {
        max-width: 35px;
    }

    .ranking-item {
        grid-template-columns: 35px auto auto;
    }

    .ranking-item-name {
        font-size: 20px;
    }

    .ranking-item-point {
        padding-top: 0px;
    }

    div#sc_about p {
        font-size: 16px;
    }

    .text-next {
        font-size: 12px;
    }

    .box-countdowntime span.item-time {
        background: transparent;
        min-width: 35px;
        padding: 0px;
    }

    .box-countdowntime span.item-time span.time-t {
        color: #d15d55;
    }

    .box-countdowntime span.item-time span.time-text {
        margin-bottom: 10px;
    }

    .view-sm button {
        font-size: 14px;
    }

    .gr-btn-payment button {
        padding: 5px 35px;
        font-size: 20px;
    }

    .gr-ip-payment label {
        font-size: 13px;
    }

    .total-wallet p {
        font-size: 14px;
    }
}